import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Subscribe from "../../components/subscribe"
import CustomTooltipbtn from "../../components/tooltipbutton";
import { StaticImage } from "gatsby-plugin-image";

const winternappPage = () => (
  <Layout siteTitle={"Become a WiNtern"} >

    <main>
        <div className="relative px-6 lg:px-8">
          <div className="mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
            <div>
              <div>
                <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">
                Become a WiNtern
                </h1>
                <p className="mt-6 text-lg leading-8 font-light text-gray-600 sm:text-center">
                WiN awards students an eight-week internship at UT Neuroscience or Dell Medical Neurology in a research or a clinical setting. Students gain significant knowledge about the fields of neuroscience and neurology, hands-on experience, and personal and professional growth. The internship includes a $5,000 stipend so the student can supplement earnings while spending the summer learning. 
                </p>
                <div className="mt-8 flex gap-x-4 sm:justify-center">
                  {/* <CustomTooltipbtn
                    zIndex="z-30" 
                    tooltipTitle="Applications open in January. Please check back then!" 
                    buttonClass="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                    buttonContent="Apply Now" 
                    showArrow={true} 
                    link="https://bit.ly/2024winapp"
                  /> */}
                  <a
                    href="#learn-more"
                    className="z-30 inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                  >
                    Learn More{' '}
                    <span className="text-gray-400" aria-hidden="true">
                      &rarr;
                    </span>
                  </a>
                </div>
              </div>
              <div className="z-20 absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <svg
                  className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                  viewBox="0 0 1155 678"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                  <defs>
                    <linearGradient
                      id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                      x1="1155.49"
                      x2="-78.208"
                      y1=".177"
                      y2="474.645"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9089FC" />
                      <stop offset={1} stopColor="#FF80B5" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
    </main>

    <div className="bg-white py-16 lg:py-24">
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="relative overflow-hidden rounded-xl bg-indigo-500 py-24 px-8 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
          <div className="absolute inset-0 object-top opacity-50 mix-blend-multiply saturate-0 filter">
          <div class="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
              <StaticImage 
              src="../../images/WinternApplyBanner1.jpg" 
              alt="Group of WiNterns" 
              class="h-full w-full"
              objectPosition={"top"} />
            </div>
          </div>
          <div className="relative lg:col-span-1">
            <blockquote className="mt-6 text-white">
              <p className="text-xl font-medium sm:text-2xl">
              My WiN internship in 2019 was quite influential to where I am today. It was one of my first introductions to applied engineering and how it can be used to get better results in medicine.
              </p>
              <footer className="mt-6">
                <p className="flex flex-col font-medium">
                  <span>Maya</span>
                  <span>2019 WiNtern, Ann Richards School for Young Women Leaders, Rice University</span>
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div id='learn-more' className="relative overflow-hidden bg-white py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-indigo-600">Student WiNterns</span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              A Day in the Life
            </span>
          </h1>
        </div>
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          <p>
          Each WiNtern is assigned to a faculty host and their lab. On a daily basis, she shadows and works hands-on with her faculty host and lab team to understand lab protocol, complete research tasks, and learn how the Scientific Method furthers research in that lab and in the fields of neuroscience and neurology.
          <br /><br />
          Throughout the internship, interns come together for workshops and speaker sessions to broaden knowledge in these fields and to enhance personal and professional development. 
          <br /><br />
          After four weeks, the interns share experiences and lab research through presentations and lab visits. At the internship’s completion, each intern makes a final Presentation of Learning.
          <br /><br />
          <strong>Requirements</strong> 
          </p>
          <ul>
            <li>Student must be 18 years old by internship start date</li>
            <li>Interest in neuroscience and neurology</li>
            <li>Commitment to spending a minimum of 30 hours per week in the internship</li>
            <li>A completed online application, which will be accessible starting from January 2025.</li>
            <li>Resume, transcript, and faculty recommendation are required as part of application.</li>
          </ul>
          {/* <p>
          <strong>Important Dates</strong><em><br /><br />Don't miss out on essential application and internship timelines. Subscribe to our WiNsider newsletter below, and you'll be the first to know about the latest announcements and updates.</em> 
          </p>
          <ul>
            <li>Application deadline: March 1, 2024</li>
            <li>Announcement of recipients: Week of April 1, 2024</li>
            <li>Internship dates: June 3, 2024 - August 9, 2024</li>
          </ul> */}
          <p>
          While the WiNternship Application is closed for 2024, if you would like to join our mailing list to be notified of the next open window and other opportunities, please <a href="https://form.jotform.com/233304951087153" class="text-indigo-600 hover:text-indigo-700">fill out our form</a>. 
          </p>

          {/* <CustomTooltipbtn
              zIndex="z-40" 
              tooltipTitle="Applications open in January. Please check back then!" 
              buttonClass="z-30 inline-block rounded-lg px-4 py-1.5 sm:text-base text-sm font-semibold leading-7 text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
              buttonContent="Download the 2024 WiNternship Information Handout" 
              showArrow={true}
              link="https://drive.google.com/uc?export=download&id=1agPS_hsiM8Bnnm3nqOkpXvDpmAqFgU0-" 
            /> */}
        </div>
      </div>
    </div>

    {/* <div className="relative sm:my-12 bg-gray-900">
      <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/WinternApplyBanner2.jpg"
          alt="Happy WiNterns"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="pr-6 pl-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Next steps</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white">To Apply</p>
          <p className="mt-6 text-base leading-7 text-gray-300">
          First, download the application to prepare your answers to the questions. When ready, copy and paste answers into this form and submit. You will be asked to upload your resume and transcript as part of the application form. You may not save this form and return later.
          </p>
          <div className="mt-8">
            <CustomTooltipbtn
              zIndex="z-40" 
              tooltipTitle="Applications open in January. Please check back then!" 
              buttonClass="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
              buttonContent="Get Started" 
              showArrow={true}
              link="https://bit.ly/2024winapp" 
            />
          </div>
        </div>
      </div>
    </div> */}

    {/* email subscribe */}
    <Subscribe />

    </Layout>
)

export const Head = () => <Seo title="Become a WiNtern" />

export default winternappPage